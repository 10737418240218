import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  cadastro(token: string, data: any){
    return this.http.post(`${environment.baseAPI}${environment.cadastrarUsuario}`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  getUserInfo(){
    const token = this.getAuthCookie();
    return this.http.get(
      `${environment.baseAPI}${environment.dadoscadastro}?apikey=${environment.apikey2}`,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) }
    );
  }

  updateUserInfo(data: any){
    const token = this.getAuthCookie();
    return this.http.put(
      `${environment.baseAPI}${environment.atualizarcadastro}?apikey=${environment.apikey2}`, data,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) }
    )
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  getTokenInfo(token: string){
    try{
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
          .join('')
      );
      return JSON.parse(jsonPayload);
    }
    catch(e){
      return {};
    }
  }

  getAuthCookie(){
    return this.getCookieValue('__pe_t');
  }

  getRefreshTokenCookie(){
    return this.getCookieValue('__pe_r');
  }

  getCookieValue(name: string){
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return '';
  }

  setCookie(name: string, value: string, expires?: Date){
    var expiresString = '';
    if(expires){
      expiresString = '; expires=' + expires.toUTCString();
    }
    document.cookie = name + '=' + value + expiresString + '; path=/';
  }

  logout(){
    const token = this.getAuthCookie();
    const refreshToken = this.getRefreshTokenCookie();
    this.setCookie('__pe_t', '', new Date(0));
    this.setCookie('__pe_r', '', new Date(0));
    window.location.href = '/keycloak/?action=logout&refresh_token=' + refreshToken + '&authorization_code=' + token;
  }
}