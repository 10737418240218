<app-modal *ngIf="showAvaliacao" class="avaliacao-modal" [variation]="'right'">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-chat.svg" alt="" srcset="">
    </div>
    <div class="m-body">
        <div [class]="currentOption === -1 ? '' : 'has-selected'">
            <h3 class="text-center mb-3 font-bold">O que achou do novo portal de serviços?</h3>
            <div class="avaliacao-portal-options">
                <div [class]="'avaliacao-portal-option ' + (currentOption === 0 || currentOption === -1 ? 'active' : '')" (click)="ToggleOption(0)">
                    <img src="../../../../assets/image/icons/icon-face-0.svg" alt="Muito ruim">
                    <label>Muito ruim</label>
                </div>
                <div [class]="'avaliacao-portal-option ' + (currentOption === 1 || currentOption === -1 ? 'active' : '')" (click)="ToggleOption(1)">
                    <img src="../../../../assets/image/icons/icon-face-1.svg" alt="Ruim">
                    <label>Ruim</label>
                </div>
                <div [class]="'avaliacao-portal-option ' + (currentOption === 2 || currentOption === -1 ? 'active' : '')" (click)="ToggleOption(2)">
                    <img src="../../../../assets/image/icons/icon-face-2.svg" alt="Bom">
                    <label>Bom</label>
                </div>
                <div [class]="'avaliacao-portal-option ' + (currentOption === 3 || currentOption === -1 ? 'active' : '')" (click)="ToggleOption(3)">
                    <img src="../../../../assets/image/icons/icon-face-3.svg" alt="Ótimo">
                    <label>Ótimo</label>
                </div>
            </div>
            <div class="col-12" *ngIf="currentOption === 0 || currentOption === 3">
                <fieldset class="reset" for="nome-completo">
                <legend class="reset">Conte-nos mais da sua experiência. (Opcional)</legend>
                <textarea class="form-control" [maxlength]="characterLimit" id="avaliacao" name="avaliacao" [(ngModel)]="avaliacaoTexto" #ctrl="ngModel"></textarea>
                </fieldset>
                <div class="label-character-remaining">{{characterLimit - avaliacaoTexto.length}} caracteres restantes</div>
            </div>
        </div>
    </div>
    <div class="m-action full-width">
      <a class="btn btn-form-submit full-width" (click)="SendAvaliacaoPortal()">Enviar</a>
      <a class="btn btn-popup-back full-width" (click)="CloseAvaliacao()">Cancelar</a>
    </div>
</app-modal>

<app-modal *ngIf="showResult">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png">
    </div>
    <div class="m-body">
        <h3 class="text-center mb-3 font-bold">Sua avaliação foi enviada com sucesso.</h3>
    </div>
    <div class="m-action full-width">
      <a class="btn btn-popup-back full-width" (click)="CloseAllPopups()">Fechar</a>
    </div>
</app-modal>

<app-modal *ngIf="formError">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png">
    </div>
    <div class="m-body">
        <h3 class="text-center mb-3 font-bold">Houve um erro ao enviar a sua avaliação.</h3>
    </div>
    <div class="m-action full-width">
      <a class="btn btn-popup-back full-width" (click)="CloseAllPopups()">Fechar</a>
    </div>
</app-modal>

