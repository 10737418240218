<div class="container">
    <h3 class="title text-spaced">{{orgaoTitulo}} <div class="print-title" (click)="BaixarLista()"><img src="../../../../assets/image/icons/icon-download.svg" alt="Baixar"><div>Baixar carta de serviço</div></div></h3>
    <div class="accordion accordion-flush" id="accordionAgendamento">
        <div class="accordion-item" *ngFor="let servico of servicos; let i = index">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+ i" aria-expanded="false" aria-controls="flush-collapseThree">
                <div class="title-medium">
                    {{servico.nome}}
                    <div class="servico-digital" *ngIf="servico.online">Serviço digital</div>
                </div>
            </button>
          </h2>
          <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
            <div class="accordion-body">
                <!-- <div class="header">
                    <h4>{{servico.nomeServico}}</h4>
                </div> -->

                <div class="servico-digital" *ngIf="servico.online">Serviço digital</div>
                
                <div class="main">
                    <p [innerHTML]="servico.descricao" class="richtext-font-1"></p>
                    <div class="info">
                        <a href="servico/informacoes-servico/{{servico.id}}/{{orgaoID}}" rel="noopener noreferrer">Mais informações</a>
                        <!-- <a href="agendamento/agendar/{{servico.id}}" class="btn-agendamento" rel="noopener noreferrer" *ngIf="servico.url && servico.online">Começar agora</a> -->
                        <!-- <a href="agendamento/agendar/{{servico.id}}" class="btn-agendamento" rel="noopener noreferrer" *ngIf="!servico.url && servico.online">Agendar</a> -->
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>

<app-modal *ngIf="showDownloadPopup">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <h3 class="text-center mb-3 font-bold">Carta baixada com sucesso</h3>
    <p class="font-style-2">Verifique os seus arquivos baixados ou abra o gerenciador de arquivos para acessá-lo. Caso não tenha conseguido baixar a carta de serviço, tente novamente.</p>
  </div>
  <div class="m-action full-width">
    <a (click)="CloseAllPopups()" class="btn-form-return">Voltar</a>
    <a (click)="BaixarLista()" class="btn-form-submit">
        <img src="../../../../assets/image/icons/icon-download.png">
        Baixar novamente
    </a>
  </div>
</app-modal>