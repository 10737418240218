import { Component, ElementRef, Input } from '@angular/core';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-seletor',
  templateUrl: './seletor.component.html',
  styleUrls: ['./seletor.component.css']
})
export class SeletorComponent {
  @Input() Placeholder: string = '';
  @Input() ClearEvent: string = '';
  @Input() options: any[] = [];
  @Input() error: string = '';
  @Input() titulo: string = '';
  @Input() LabelField: string = '';
  @Input() ValueField: string = '';
  @Input() OnChange: Function = () => {};
  @Input() FormatLabel: Function = (label: any) => label;
  @Input() Icon: string = "";
  @Input() SizePreset: string = "";
  @Input() Pagination: boolean = false;
  @Input() GetMore: Function = () => {};
  @Input() Variation: string = "0";
  @Input() IsSearchable: boolean = false;

  constructor (private elRef: ElementRef, private eventsService: EventsService) {
    this.rootElement = elRef;
  }

  rootElement: ElementRef;

  isOpen: boolean = false;

  value: string = '';

  page: number = 0;

  resultOptions: any[] = [];

  ngOnChanges(changes: any){
    if(changes.options && this.IsSearchable){
      const selectorOutput = this.rootElement.nativeElement.querySelector('.selector-output');
      if(selectorOutput){
        selectorOutput.value = this.value;
      }
      this.resultOptions = [];
    }
  }

  ngOnInit(){
    if(this.ClearEvent){
      this.eventsService.when(this.ClearEvent, () => {
        this.value = "";
        this.page = 0;
        this.resultOptions = [];
      });
    }
  }

  OpenSelectBox(){
    this.isOpen = true;
  }

  Select(id: any, label?: string){
    const formattedvalue = label ? this.FormatLabel(label) : id;
    this.value = formattedvalue == 'Invalid Date' ? (label as any) : label;
    this.OnChange(id);
    if(this.IsSearchable){
      const selectorOutput = this.rootElement.nativeElement.querySelector('.selector-output');
      if(selectorOutput){
        selectorOutput.value = this.value;
      }
    }
    setTimeout(() => {
      this.Close();
    },100);
  }

  Open(){
    this.isOpen = true;
  }

  Close(){
    this.isOpen = false;
  }

  TrackClick(event: any){
    if(event?.target && event?.target?.classList){
      if (!this.rootElement.nativeElement.contains(event.target) && !event.target.classList.contains('ignore-select')) {
        this.Close();
      }
    }
  }

  PreventInput(event: any){
    event.preventDefault();
    event.stopPropagation();
  }

  getMore(event: any){
    this.page++;
    this.GetMore(this.page);
  }

  UpdateSearch(e: any){
    const self = this;
    if(e.target.value){
      this.resultOptions = this.options.filter(function(item: any){
        const val = (self.LabelField ? item[self.LabelField] : item);
        return val.toLowerCase().includes(e.target.value.toLowerCase());
      });
    }else{
      this.resultOptions = [];
    }
  }
}
