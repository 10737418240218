import { Component, Input, ElementRef } from '@angular/core';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-print-document',
  templateUrl: './print-document.component.html',
  styleUrls: ['./print-document.component.css']
})
export class PrintDocumentComponent {
  @Input() Name: string = '';

  constructor (private elRef: ElementRef, private eventsService: EventsService) {
    this.rootElement = elRef;
  }

  rootElement: ElementRef;

  ngOnInit(){
    this.eventsService.when('event-print-' + this.Name, (data: any) => {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('style','display:none;');
      this.rootElement.nativeElement.appendChild(iframe);
      const iframeDoc = iframe.contentDocument || iframe?.contentWindow?.document;
      if(iframeDoc){
        iframeDoc.open();
        iframeDoc.write(`
          <!DOCTYPE html>
          <html>
          <head>
            <style>
              .text-16p700{
                  font-family: arial;
                  font-size: 16px;
                  font-weight: 700;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
              }

              .text-12p400{
                  font-family: arial;
                  font-size: 12px;
                  font-weight: 400;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
              }

              .text-16p400{
                font-family: arial;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
              }

              .text-14p700{
                font-family: arial;
                font-size: 14px;
                font-weight: 700;
                text-align: left;
              }

              .text-14p400{
                font-family: arial;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height:21px;
              }

              .separator{
                  width: 100%;
                  background:#999999;
                  margin-top:24px;
                  margin-bottom:24px;
                  border-bottom:2px solid #999999;
              }

              .text-left{
                text-align: left;
              }

              .opacity{
                opacity: 0;
              }

              .mb-15{
                margin-bottom:15px;
              }
              
              .mb-10{
                margin-bottom:10px;
              }

              .spacer16p{
                height:16px;
              }

              .spacer10p{
                height:10px;
              }
            </style>
          </head>
          <body>
            ${this.rootElement.nativeElement.innerHTML}
          </body>
          </html>
        `);
        iframeDoc.close();
        iframe.onload = () => {
          console.log('loaded',iframe.contentWindow);
            if(iframe.contentWindow){
              iframe.contentWindow.focus();
              iframe.contentWindow.print();
            }
        }
      }
    });
  }
}
