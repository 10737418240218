import { Component, Input } from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-canais-atendimento',
  templateUrl: './canais-atendimento.component.html',
  styleUrls: ['./canais-atendimento.component.css']
})
export class CanaisAtendimentoComponent {
  @Input() Servico: any = {};

  CurrentTab: number = 0;

  MunicipiosAtendimento: any = [];

  UnidadesAtendimento: any = [];

  UnidadeSelecionada: any = {};

  loadedMap: boolean = false;

  semCanaisAtendimento: boolean = false;

  responsiveState: string = '';

  constructor(
    private eventsService: EventsService,
    private http: HttpClient,
    private responsiveService: ResponsiveService
  ){}

  ngOnInit(){
    // this.http.get(`${environment.baseAPI}/apiman-gateway/carta-servico/servicos/1.0/buscarContando/${this.Servico.id}`)
    //   .subscribe((data: any) => {
    //     if(data){
    //       this.ServicoProducao = data;
    //     }
    // });
    if(this.Servico.listaUnidadesAtendimento && this.Servico.listaUnidadesAtendimento.length > 0){
      this.CurrentTab = 0;
    }else if(this.Servico.servicosOnline && this.Servico.servicosOnline.length > 0){
      this.CurrentTab = 1;
    }else if(this.Servico.servicoAplicativoMovel && this.Servico.servicoAplicativoMovel.length > 0){
      this.CurrentTab = 2;
    }else if(this.Servico.servicoEmail && this.Servico.servicoEmail.length > 0){
      this.CurrentTab = 3;
    }else if(this.Servico.servicosTelefonicos && this.Servico.servicosTelefonicos.length > 0){
      this.CurrentTab = 4;
    }else if(this.Servico.servicoMensagemInstantanea && this.Servico.servicoMensagemInstantanea.length > 0){
      this.CurrentTab = 5;
    }else{
      this.semCanaisAtendimento = true;
    }
    this.Servico.listaUnidadesAtendimento.forEach((Unidade: any) => {
      const municipio = Unidade.unidadeAtendimento.enderecos[0].municipio;
      if(this.MunicipiosAtendimento.indexOf(municipio) === -1) {
        this.MunicipiosAtendimento.push(municipio);
      }
    });
    this.MunicipiosAtendimento.sort();
    if(this.MunicipiosAtendimento.length === 1){
      this.OnSelectMunicipio(this.MunicipiosAtendimento[0]);
    }
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  OnSelectMunicipio(name: string){
    this.eventsService.emit('clear-unidade', false);
    this.UnidadeSelecionada = {};
    this.UnidadesAtendimento = [];
    this.Servico.listaUnidadesAtendimento.forEach((Unidade: any) => {
      const municipio = Unidade.unidadeAtendimento.enderecos[0].municipio;
      const unidadeNome = Unidade.unidadeAtendimento.nome;
      if(municipio === name) {
        this.UnidadesAtendimento.push(unidadeNome);
      }
    });
    if(this.UnidadesAtendimento.length === 1){
      this.OnSelectUnidade(this.UnidadesAtendimento[0]);
    }
    this.UnidadesAtendimento.sort();
  }

  OnSelectUnidade(name: string){
    this.Servico.listaUnidadesAtendimento.forEach((Unidade: any) => {
      const unidadeNome = Unidade.unidadeAtendimento.nome;
      if(unidadeNome === name){
        this.UnidadeSelecionada = Unidade;
        this.InitMap(this.UnidadeSelecionada.unidadeAtendimento.enderecos[0]);
      }
    });
  }

  formatEndereco(endereco: any){
    return endereco.logradouro + ', ' + endereco.numero + ', ' + endereco.bairro + ', ' + endereco.municipio + ', ' + endereco.uf + ' - CEP: ' + endereco.cep;
  }

  formatEnderecoSemPontuacao(endereco: any){
    return endereco.logradouro + ' ' + endereco.numero + ' ' + endereco.bairro + ' ' + endereco.municipio + ' ' + endereco.uf + '  ' + endereco.cep;
  }

  formatDiaSemana(horario: any){
    return ({
      'DOM': 'Domingo',
      'SEG': 'Segunda-feira',
      'TER': 'Terça-feira',
      'QUA': 'Quarta-feira',
      'QUI': 'Quinta-feira',
      'SEX': 'Sexta-feira',
      'SAB': 'Sábado',
    } as any)[horario.diaSemana] + ' - ' + horario.horaInicio + ' às ' + horario.horaFim;
  }

  InitMap(endereco: any){
    this.loadedMap = false;
    var map = document.createElement('div');
    map.setAttribute('id', 'map');
    map.setAttribute('style','height: 300px; width: 100%;overflow:hidden;');
    const currentmap = document.querySelector('#map');
    if(currentmap){
      currentmap.replaceWith(map);
    }
    if(endereco.hasOwnProperty('latitude')){
      this.loadedMap = true;
      setTimeout(() => {
        this.ReloadMap([{
          lat: endereco.latitude,
          lon: endereco.longitude,
        }]);
      }, 1000);
    }else{
      this.http
        .get(`https://nominatim.openstreetmap.org/search?format=json&q=${this.formatEnderecoSemPontuacao(endereco)}`)
        .subscribe((data: any) => {
          if (data.length > 0) {
            this.loadedMap = true;
            setTimeout(() => {
              this.ReloadMap(data);
            }, 1000);
          }
      });
    }
  }

  ReloadMap(data: any){
    const latitude = data[0].lat;
    const longitude = data[0].lon;
    const L = (window as any).L;
    const zoomLevel = 17;
    const map = L.map('map').setView([latitude, longitude], zoomLevel);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
    }).addTo(map);

    const customIcon = L.icon({
      iconUrl: '../../../../assets/image/icons/icon-marker-map.png',
      iconSize: [30, 50],
      iconAnchor: [15, 50],
    });

    const marker = L.marker([latitude, longitude], { icon: customIcon }).addTo(map);
  }

  TabNavigate(index: number){
    this.CurrentTab = index;
  }

  GetLinkRedeSocial(link: any){
    if(link.descricao?.toLowerCase().includes('whatsapp')){
      return 'https://api.whatsapp.com/send?phone=' + link.numero.replace(/\D/g,'').replaceAll(' ','');
    }
    if(link.descricao?.toLowerCase().includes('telegram')){
      return 'https://t.me/+55' + link.numero.replace(/\D/g,'').replaceAll(' ','');
    }
    return '';
  }

  GetDescricaoRedeSocial(link: any){
    if(link.descricao?.toLowerCase().includes('whatsapp')){
      return 'Enviar mensagem via Whatsapp';
    }
    if(link.descricao?.toLowerCase().includes('telegram')){
      return 'Enviar mensagem via Telegram';
    }
    return 'Enviar mensagem';
  }
}
