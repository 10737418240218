import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { TemaServico } from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class ListaTemaService {

  constructor(private http: HttpClient) { }
   
  getAll(){
    return this.http.get<TemaServico[]>(`${environment.baseAPI}${encodeURI(environment.tema)}`);
  }

  getOrgaosAll(){
    return this.http.get(`${environment.baseAPI}${environment.listarTemaOrgaos}?apiKey=${environment.apikey}`);
  }

  downloadOrgaoServiceList(orgaoID: any, serviceList: any[]){
    const options = { headers: new HttpHeaders({ 'Accept': '*' }), 'responseType': 'blob' as 'json' };
    return this.http.post(`${environment.baseAPI}${environment.baixarOrgaosServicos}?apiKey=${environment.apikey}`,{
      orgaoId: orgaoID,
      idsServicos: serviceList,
    }, options);
  }

  DownloadBlobAs(blob: Blob, filename: string){
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
    anchor.click();
  }
}
