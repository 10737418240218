import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-indicador-servicos',
  templateUrl: './indicador-servicos.component.html',
  styleUrls: ['./indicador-servicos.component.css']
})
export class IndicadorServicosComponent {

  options: any = {
    total: 689,
    digitais: 273,
  };

  constructor(
    private http: HttpClient
  ) { }

 ngOnInit(){
  // this.http.get(`${environment.baseAPI}${environment.indicadores}?apiKey=${environment.apikey}`)
  // .subscribe((result: any) => {
  //     if(result){
  //       this.options = result;
  //     }
  // });
 }
}
