import { Injectable } from '@angular/core';
import { EventsService } from '../services/events.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(
    private eventsService: EventsService,
  ) { }

  print(name: string){
    this.eventsService.emit('event-print-' + name, {});
  }
}
