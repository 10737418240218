<section class="form-agendamento">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <h6 [hidden]="HideTitle">Local de atendimento</h6>

        <form class="row needs-validation" novalidate>

          <app-seletor
            [options]="ListaMunicipio.sort()"
            [titulo]="'Município*'"
            [error]="form.message['municipio']"
            [OnChange]="OnMunicipioSelect.bind(this)"
            [ClearEvent]="'section2-municipio'"
            [IsSearchable]="true"
          ></app-seletor>

          <app-seletor
            [options]="ListaUnidade"
            [titulo]="'Unidade de atendimento*'"
            [error]="form.message['unidade']"
            [OnChange]="OnUnidadeSelect.bind(this)"
            [LabelField]="'nome'"
            [ValueField]="'id'"
            [ClearEvent]="'section2-unidade'"
            [IsSearchable]="true"
          ></app-seletor>

          <div class="field-split">
            <div class="col-6">
              <fieldset class="reset {{form.message['dataatendimento'] ? 'has-error' : ''}}" for="data-atendimento">
                <legend class="reset">Data*</legend>
                <p class="data-atendimento-label form-input-data" (click)="InputFocus('#data-atendimento')"><a>{{formatarData(form.fields['dataatendimento'])}}</a></p>
                <input type="text" class="form-control" id="data-atendimento" name="data-atendimento" autocomplete="off" autocorrect="off" required [(ngModel)]="form.fields['dataatendimento']" #ctrl="ngModel" (click)="OnDatePickerFocus()" (focus)="OnDatePickerFocus()" (blur)="OnDatePickerBlur()">
                <div class="ngb-dtpicker" *ngIf="showDatePicker">
                  <ngb-datepicker
                    [markDisabled]="isDisabled"
                    [minDate]="minPickerDate"
                    (dateSelect)="onDateSelect($event)"
                    (navigate)="onDateSelectorNavigate($event)"
                    [navigation]="'arrows'">
                  </ngb-datepicker>
                </div>
              </fieldset>
              <div class="error-label" *ngIf="form.message['dataatendimento']">
                {{form.message['dataatendimento']}}
              </div>
            </div>
            <div class="col-6">
              
              <app-seletor
                [options]="HorariosDisponiveis"
                [titulo]="'Horário*'"
                [error]="form.message['horario']"
                [OnChange]="OnHorarioSelect.bind(this)"
                [LabelField]="'horario'"
                [FormatLabel]="formataHorario.bind(this)"
                [Icon]="'icon-clock.png'"
                [SizePreset]="'right'"
                [ClearEvent]="'section2-horario'"
                [Pagination]="EnableHorarioPagination"
                [GetMore]="GetHorariosMore.bind(this)"
                [IsSearchable]="true"
              ></app-seletor>

              <div class="error-label" *ngIf="semHorariosDisponiveis">
                Nenhum horário disponível para a data selecionada.
              </div>
            </div>
          </div>

          
          <!-- <div class="col-3" *ngFor="let horario of HorariosDisponiveis">
              <div class="btn-timer {{HorarioSelecionado == horario.horario ? 'active' : ''}}">
                  <input class="form-control inp-time {{HorarioSelecionado == horario.horario ? 'active' : ''}}" type="text" name="hora-cons" value="{{formataHorario(horario.horario)}}" (click)="OnHorarioSelect(horario)" readonly />
              </div>
          </div> -->
          
          <re-captcha *ngIf="variation == 'reagendamento'" (resolved)="whenCaptchaSolved($event)"></re-captcha>

          <div class="col-12 min-h-100p">
            <hr>
            <div class="buttons {{variation == 'reagendamento' ? 'flex-end' : ''}}">
              <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
              <button class="btn btn-form-submit" (click)="submit()">{{variation == 'reagendamento' ? 'Reagendar' : 'Avançar'}}</button>
          </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</section>
  
  