<section class="header-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="info">
          <div class="info-title">
            <!-- <a (click)="navigate('back')" class="cursor-pointer" [hidden]="currentForm == 'section1'">
              <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
            </a> -->
            <h4 [class]="currentForm == 'section1' ? 'no-m-left' : ''">{{allForms[currentForm].title}}</h4>
          </div>
          <!-- <a (click)="navigate('close')" class="cursor-pointer">
            <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
          </a> -->
        </div>
      </div>
    </div>
  </div>
</section>

<app-steps
  [current]="currentForm"
  [available]="allForms"
></app-steps>

<div *ngIf="servico | async as item">
  <app-modal *ngIf="result.submitted && result.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body font-style-1">
      <h3 class="text-center text-medium">Agendamento confirmado</h3>
      <p>Enviamos a listagem de documentos que devem ser apresentados no balcão de atendimento e o código do protocolo para o seu email.</p>
      <p class="m-top">Protocolo:</p>
      <p class="m-bottom uppercase">{{agendamentoProtocolo}}</p>
    </div>
    <div class="m-action">
      <a href="/agendamento/documentos/{{item.id}}" class="btn-form-submit btn-info">Informações de atendimento</a>
      <a (click)="AvaliarServico(item)" class="btn-form-submit btn-avaliar cursor-pointer"><img src="../../../../assets/image/icons/icon-star-border.png">Avaliar agendamento</a>
      <a (click)="PrintAgendamentoFn()" class="btn-form-submit btn-print cursor-pointer"><img src="../../../../assets/image/icons/icon-print.svg">Imprimir protocolo</a>
      <a href="/" class="btn-form-submit btn-avaliacao m-top-24p">
        <!-- <img src="../../../../assets/image/icons/icon-home-blue.png"> -->
        Voltar para o início
      </a>
    </div>
  </app-modal>

  <app-print-document [Name]="'AgendamentoDocumento'">
    <div style="padding-left:14px;">
      <div class="separator opacity"></div>
      <div class="separator opacity"></div>
      <div class="text-16p400">PE Cidadão - Agendamento confirmado</div>
      <div class="spacer16p"></div>
      <div class="text-14p700"><b>{{Servico.nome}}</b></div>
      <div class="separator"></div>
      <div class="text-16p400 mb-10">Protocolo:</div>
      <div class="spacer16p"></div>
      <div class="text-14p700"><b>{{agendamentoProtocolo}}</b></div>
      <div class="separator"></div>
      <div class="text-16p400 text-left">Senha:</div>
      <div class="spacer16p"></div>
      <div class="text-14p700 text-left">{{numeroSenha}}</div>
      <div style="margin-bottom:36px;"></div>
      <div class="text-14p400 text-left"><b>Dados do(a) solicitante</b></div>
      <div class="spacer10p"></div>
      <div class="text-14p400 text-left"><b>Nome:</b> {{getField('section1', 'nomecompleto')}}</div>
      <div class="text-14p400 text-left"><b>CPF:</b> {{getField('section1', 'cpf')}}</div>
      <div class="text-14p400 text-left"><b>Nascimento:</b> {{getField('section1', 'nascimento')}}</div>
      <div class="text-14p400 text-left"><b>E-mail:</b> {{getField('section1', 'email')}}</div>
      <div class="text-14p400 text-left"><b>Celular:</b> {{getField('section1', 'celular')}}</div>
      <div class="separator opacity"></div>
      <div style="margin-left:-12px;padding-left:12px;padding-right:12px;padding-top:16px;padding-bottom:16px;border:2px solid #999999;">
        <div class="text-14p400 text-left"><b>Dados de atendimento</b></div>
        <div class="spacer10p"></div>
        <div class="text-14p400 text-left">{{Servico.nome}}</div>
        <div class="text-14p400 text-left"><b>Município:</b> {{getField('section3', 'municipio')}}</div>
        <div class="text-14p400 text-left"><b>Endereço:</b> {{getField('section3', 'unidadeendereco')}}</div>
        <div class="text-14p400 text-left"><b>CEP:</b> {{getField('section3', 'cep')}}</div>
        <div style="margin-bottom:20px;"></div>
        <div class="text-14p400 text-left"><b>Local de atendimento:</b> {{getField('section3', 'unidadenome')}}</div>
        <div class="text-14p400 text-left">Data: {{getField('section3', 'dataatendimento')}}</div>
        <div class="text-14p400 text-left">Horário: {{getField('section3', 'horario')}}</div>
      </div>
    </div>
  </app-print-document>

  <app-modal *ngIf="result.submitted && !result.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro no seu agendamento</h3>
      <p>Aguarde alguns minutos.</p>
      <p>Volte a tela de agendamento e realize novamente o processo.</p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
  </app-modal>

  <app-modal *ngIf="!servicoGratuito && !aceiteServicoPago && parametroServico.geraGuiaPagamento">
    <div class="m-body m-body-servico-pago">
      <h3 class="mb-3 text-medium">Serviço pago</h3>
      <!-- <p [innerHTML]="Servico.valor.replaceAll('\n','<br>')" class="font-style-2"></p> -->
      <p class="font-style-1">A seguir, preencha os campos para gerar o boleto da taxa de emissão, que pode ser pago em qualquer rede bancária.</p>
      <p class="font-style-1"><b>Pessoas a partir de 60 anos de idade estão isentas do pagamento.</b></p>
      <p class="font-style-1"><b>Caso já tenha efetuado o pagamento,</b> preencha os seus dados para prosseguir com o agendamento do atendimento.</p>
    </div>
    <div class="m-action m-action-servico-pago">
      <a href="/" class="btn btn-popup-back">Cancelar</a>
      <a (click)="aceitarServicoPago()" class="btn-form-submit">Entendi e quero continuar</a>
    </div>
  </app-modal>

  <app-modal *ngIf="dadosDAE.erro && !formErrorPopup">
    <div class="m-body">
      <h3 class="text-center mb-3">Não foi possível localizar as informações digitadas no sistema.</h3>
      <p>1. Verifique suas informações e tente novamente.</p><br>
      <p>2. Caso o problema persista, procure um posto de atendimento do IITB mais próximo.</p>
    </div>
    <div class="m-action">
      <a (click)="voltarErroDAE()" class="btn-form-submit">Voltar</a>
    </div>
  </app-modal>

  <app-modal *ngIf="formErrorPopup != ''">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro no seu agendamento</h3>
      <p [innerHTML]="formErrorPopup.replaceAll('\n','<br>')"></p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
  </app-modal>
</div>

<app-requerente-agendamento
  [OnNavigate]="navigate.bind(this)"
  [OnSubmit]="submit.bind(this)"
  [hidden]="currentForm != 'section1'"
  [FormName]="'section1'"
  [FormData]="formData"
  [servico]="servico"
></app-requerente-agendamento>

<app-pagamento
  [OnNavigate]="navigate.bind(this)"
  [OnSubmit]="imprimirDAE.bind(this)"
  [hidden]="currentForm != 'pagamento'"
  [FormData]="formData"
  [erroPagamento]="pagamentoErro"
  [servico]="Servico"
></app-pagamento>

<app-dependentes
  [OnNavigate]="navigate.bind(this)"
  [OnSubmit]="submit.bind(this)"
  [hidden]="currentForm != 'section2'"
  [FormName]="'section2'"
  [FormData]="formData"
></app-dependentes>

<app-selecao-unidade
  [OnNavigate]="navigate.bind(this)"
  [OnSubmit]="submit.bind(this)"
  [hidden]="currentForm != 'section3'"
  [FormName]="'section3'"
  [FormData]="formData"
  [ListaMunicipio]="listaMunicipio"
  [Servico]="servico"
></app-selecao-unidade>

<app-confirmar-agendamento
  [OnNavigate]="navigate.bind(this)"
  [OnSubmit]="submit.bind(this)"
  [hidden]="currentForm != 'section4'"
  [OnSessionToken]="OnSessionToken.bind(this)"
  [FormName]="'section4'"
  [FormData]="formData"
  [SessionValid]="sessionValid"
  [Protocolo]="agendamentoProtocolo"
  [Servico]="Servico"
></app-confirmar-agendamento>
