import { Component } from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.css']
})
export class AvaliacaoComponent {
  currentOption: number = -1;

  avaliacaoTexto: string = '';

  characterLimit: number = 2000;

  showAvaliacao: boolean = false;

  showResult: boolean = false;

  formError: boolean = false;

  ExibithionTimeout: number = 30 * 24 * 60 * 60 * 1000; // 30 days

  constructor(
    private eventsService: EventsService,
    private http: HttpClient,
  ) { }

  ngOnInit(){
    const self = this;
    this.eventsService.when('avaliacao-portal-hook', function(event: any){
      if(self.GetExibithionTimeout() > self.ExibithionTimeout){
        self.UpdateExibithion();
      }else{
        if(event?.target?.getAttribute('href')){
          window.location = event?.target?.getAttribute('href');
        }
      }
    });
  }

  UpdateExibithion(){
    this.showAvaliacao = true;
    localStorage.setItem('avaliacao-portal-last-exibithion', Date.now().toString());
  }

  GetExibithionTimeout(){
    return Date.now() - this.GetLastExibithion();
  }

  GetLastExibithion(){
    return parseInt(localStorage.getItem('avaliacao-portal-last-exibithion') || '0') || 0;
  }
  
  ToggleOption(id: number){
    this.currentOption = id;
  }

  SendAvaliacaoPortal(){
    if(this.currentOption !== -1){
      this.eventsService.emit('loader-toggle',true);
      this.http.post(`${environment.baseWP}/wp-json/avaliacao-portal/v1/criar`, {
        avaliacao: this.currentOption.toString(),
        observacao: this.avaliacaoTexto,
        url: window.location.href,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            return of(error.error.message);
          }else{
            return of(error.error);
          }
        })
      )
      .subscribe((data: any)=>{
        if(data.status){
          this.eventsService.emit('loader-toggle',false);
          this.CloseAvaliacao();
          this.showResult = true;
        }else{
          this.formError = true;
        }
      });
    }
  }

  CloseAvaliacao(){
    this.showAvaliacao = false;
  }

  CloseAllPopups(){
    this.CloseAvaliacao();
    this.showResult = false;
  }
}
