import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ServicosService } from 'src/app/services/servicos.service';
import { ListaTemaService } from 'src/app/services/lista-tema.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-orgao',
  templateUrl: './orgao.component.html',
  styleUrls: ['./orgao.component.css']
})
export class OrgaoComponent {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  helper : ServicosService = new ServicosService(this.http);

  temaHelper : ListaTemaService = new ListaTemaService(this.http);

  orgaoTitulo : string = "";

  orgaoID : string = "";

  servicos : any = [];

  showDownloadPopup: boolean = false;

  ngOnInit(){
    this.route.params.subscribe(params => {
      this.orgaoID = (params['id'] || '').replace('orgao-', '');
      this.helper.getServicosPorOrgao(this.orgaoID).subscribe((result: any) => {
        this.servicos = result.sort((a: any, b: any) => a.nome.localeCompare(b.nome));
      });
      this.temaHelper.getOrgaosAll().subscribe((result: any) => {
        if(Array.isArray(result)){
          result.forEach((orgao: any) => {
            if(orgao.idOrgao == this.orgaoID){
              this.orgaoTitulo = orgao.nomeOrgao;
              this.eventsService.emit('breadcrumb-update', '<a href="/servicos">Serviços</a> > ' +  '<a>' + this.orgaoTitulo + '</a>');
            }
          });
        }
      });
    });
  }

  BaixarLista(){
    const self = this;
    this.eventsService.emit('loader-toggle',true);
    this.temaHelper.downloadOrgaoServiceList(this.orgaoID, this.servicos.reduce((a: any,b: any) => a.concat(b.id),[]))
    .subscribe(function(result: any){
      self.eventsService.emit('loader-toggle',false);
      self.temaHelper.DownloadBlobAs(result, 'PECidadao-Lista-de-servicos-' + self.orgaoID + '.pdf');
      self.showDownloadPopup = true;
    });
  }

  CloseAllPopups(){
    this.showDownloadPopup = false;
  }

  back(){
    window.location.href = '/servicos';
  }

}
