<app-modal *ngIf="(formResult.submitted && !formResult.confirmed) || formErrorPopup">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro ao confirmar a nova data</h3>
      <p>{{formErrorPopup}}</p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
</app-modal>

<app-modal *ngIf="formResult.submitted && formResult.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Nova data de agendamento confirmada</h3>
      <p class="box-info">Enviamos para o e-mail que você cadastrou anteriormente o número do seu processo. Você pode usar esse número para consultar, cancelar ou reagendar o serviço.</p>
      <div class="spacer"></div>
      <p class="box-info">Município: <b>{{agendamentoConfirmarAutomatico.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio}}</b></p>
      <p class="box-info">Local de atendimento: <b>{{agendamentoConfirmarAutomatico.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}</b></p>
      <p class="box-info">Data: <b>{{formatarData(agendamentoConfirmarAutomatico.horarioAgenda.horario)}}</b></p>
      <p class="box-info">Horário: <b>{{formatarHorario(agendamentoConfirmarAutomatico.horarioAgenda.horario)}}</b></p>
      <div class="spacer"></div>
      <p class="box-info">A lista de documentos que devem ser apresentados no balcão de atendimento também foi enviada.</p>
    </div>
    <div class="m-action">
      <a href="/servico/informacoes-servico/{{agendamentoConfirmarAutomatico.horarioAgenda.servicoUnidade.servico.id}}" class="btn-form-submit btn-fullwidth">Consultar instruções de atendimento</a>
    </div>
</app-modal>

<div class="container consulta-agendamentos">
    <!-- <div class="row">
        <div class="col-12">
            <section class="header-section">
                <div class="info">
                    <div class="flex">
                        <a href="/">
                            <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
                        </a>
                        <h4>Acompanhar processo</h4>
                    </div>
                    <a href="/" ><img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" ></a>
                </div>
            </section>
        </div>
    </div> -->

    <div class="row">
        <div class="col">
            <app-loader *ngIf="isLoading"></app-loader>
            <p *ngIf="listEmpty" class="center-p">Nenhum agendamento encontrado.</p>

            <div class="single-tabs-container" *ngIf="!isLoading && !listEmpty">
                <div class="single-tabs">
                    <div [class]="'single-tab ' + (CurrentTab === i ? 'active' : '')" (click)="TabNavigate(i)" *ngFor="let aba of ListaAbas;let i = index">{{aba}}</div>
                </div>
            </div>

            <div *ngFor="let agendamento of agendamentosComFiltro;let i = index">
                <div class="box {{i === 0 ? 'active' : ''}} toggle-box-{{i}}">
                    <div class="flex space-between cursor-pointer" (click)="toggleBox('.toggle-box-' + i)">
                        <div>
                            <div class="title">{{agendamento.horarioAgenda.servicoUnidade.servico.nome}}</div>
                            <div class="box-content">
                                <p class="subtitle" *ngIf="agendamento.status == 'AGENDADO'">Dia de atendimento: {{formatarData(agendamento.horarioAgenda.horario)}}</p>
                            </div>
                            <p class="agendamento-status status-{{agendamento.status}}">{{formataStatus(agendamento.status)}}</p>
                        </div>
                        <div class="toggle-box"><div class="toggle-box-icon"></div></div>
                    </div>
                    <div class="splitter">
                        <div>
                            <div class="aviso-reagendamento" *ngIf="agendamento.status == 'A_CONFIRMAR'">
                                <div>
                                    <img src="../../../../assets/image/icons/warning.png">
                                </div>
                                <div>
                                    <p class="title">Nova data de agendamento</p>
                                    <p>Este agendamento foi alterado automaticamente. Confira a nova data e horário de atendimento sugeridos.</p>
                                </div>
                            </div>
                            <div class="box-content">
                                <p class="box-title">Dados de atendimento</p>
                                <p class="box-info">Município: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio}}</p>
                                <p class="box-info">Local de atendimento: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}</p>
                                <p class="box-info">Data: {{formatarData(agendamento.horarioAgenda.horario)}}</p>
                                <p class="box-info">Horário: {{formatarHorario(agendamento.horarioAgenda.horario)}}</p>
                                <div class="box-spacer"></div>
                            </div>
                            <div class="actions flex-col">
                                <a href="/agendamento/documentos/{{agendamento.horarioAgenda.servicoUnidade.servico.id}}" *ngIf="agendamento.status == 'AGENDADO' || agendamento.status == 'RESERVADO'"><button class="btn btn-primary">Consultar instruções de atendimento</button></a>
                                <!-- <a href="#" *ngIf="agendamento.status == 'CANCELADO'"><button class="btn btn-tertiary">Comprovante do cancelamento</button></a> -->
                            </div>
                        </div>
                        <div>
                            <div class="box-content actions" *ngIf="agendamento.status == 'A_CONFIRMAR'">
                                <p class="reagendamento-label">Você confirma a nova data e horário de atendimento que foi sugerido?</p>
                                <a href="/agendamentos/{{agendamento.protocolo}}/{{nascimento}}/{{agendamento.id}}/remarcacao"><button class="btn btn-primary">Confirmar nova data</button></a>
                            </div>
                            <div class="box-content actions" *ngIf="agendamento.status == 'A_CONFIRMAR'">
                                <hr>
                                <p class="box-description">Reagende ou Cancele o seu atendimento.</p>
                                <a href="/agendamentos/{{agendamento.protocolo}}/{{nascimento}}/{{agendamento.id}}/reagendamento"><button class="btn btn-primary btn-secondary">Reagendar atendimento</button></a>
                                <a href="/agendamentos/{{agendamento.protocolo}}/{{nascimento}}/{{agendamento.id}}/naoconfirmar"><button class="btn btn-primary btn-negative">Cancelar atendimento</button></a>
                            </div>
                            <div class="box-content actions" *ngIf="canCancelamentoHorarioMinimo[agendamento.protocolo]">
                                <hr>
                                <p class="box-description">Reagende ou Cancele o seu atendimento.</p>
                                <a href="/agendamentos/{{agendamento.protocolo}}/{{nascimento}}/{{agendamento.id}}/reagendamento"><button class="btn btn-primary btn-secondary">Reagendar atendimento</button></a>
                                <a href="/agendamentos/{{agendamento.protocolo}}/{{nascimento}}/{{agendamento.id}}/cancelamento"><button class="btn btn-primary btn-negative">Cancelar atendimento</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>