<app-loader [UseEvents]="true"></app-loader>

<app-avaliacao></app-avaliacao>

<app-modal *ngIf="!consentCookies" [variation]="'left'">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-cookie.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <h3 class="text-center mb-3 font-bold">Política de cookies</h3>
    <p class="font-style-2">Usamos cookies para melhorar sua experiência em nossos serviços e recomendar conteúdos do seu interesse. Continuando aqui, você aceita nossa <a href="/privacidade" target="_blank">Política de Privacidade e Termos de Uso</a>.</p>
  </div>
  <div class="m-action full-width">
    <a (click)="setConsentCookies()" class="btn-form-submit full-width">Entendi e quero continuar</a>
  </div>
</app-modal>

<header (window:scroll)="OnScroll($event)">
  <div class="topbar-header">
    <div class="container topbar-header">
      <div class="row">
        <div class="col">
          <img class="logo-topo" src="../assets/image/icons/icon-star.png" alt="ícone de estrela">
          <a href="https://www.pe.gov.br/" target="_blank"><p>Governo de <b>Pernambuco</b></p></a>
        </div>
      </div>
    </div>
  </div>
  <div class="container" [hidden]="!isOnTop">
    <div class="row">
      <div class="col flex space-between py-16">
        <div class="div logo-principal {{routePath === '' ? 'logo-normal' : ''}}">
          <a href="/">
            <img class="logo-topo logo-light" [src]="responsiveState == 'desktop' ? '../assets/image/logo-pe.svg' : '../assets/image/logo-pe.svg'" alt="Logo PE">
            <img class="logo-topo logo-dark" [src]="responsiveState == 'desktop' ? '../assets/image/logo-pe-dark.svg' : '../assets/image/logo-pe-dark.svg'" alt="Logo PE">
          </a>
        </div>
        <app-search-box *ngIf="responsiveState == 'desktop'"></app-search-box>
        <div class="flex acessibilidade-actions {{['', '/servicos', '/fale-conosco'].includes(routePath) ? '' : 'mobile-hidden'}}">
          <a href="javascript:IncreaseFontScale(-2)" class="high-contrast-invert"><img src="../assets/image/icons/icon-a-.png" alt="a-"></a>
          <a href="javascript:IncreaseFontScale(+2)" class="high-contrast-invert"><img src="../assets/image/icons/icon-a+.png" alt="a+"></a>
          <a href="javascript:ToggleHighContrast()" class="high-contrast-invert"><img src="../assets/image/icons/icon-contrast.png" alt="I"></a>
          <!-- <a href="/?option=oauthredirect&app_name=keycloak" class="btn-signin" *ngIf="!userInfo.logged">
            <div>Entrar com <b>gov.br</b></div>
          </a> -->
          <div class="btn-user" *ngIf="userInfo.logged" (click)="switchUserPopup()">
            <img src="../assets/image/icons/icon-user-logged.png" alt="I">
            <div class="btn-user-popup">
              <img src="../assets/image/icons/arrow-more.png" alt="I">
            </div>
          </div>
          <div class="popup-user" [hidden]="!userPopupOpen">
            <div class="popup-user-header flex">
              <div class="popup-user-circle">{{userInfo.nome.substr(0,1).toUpperCase()}}</div>
              <div>Olá {{userInfo.nome.split(' ')[0]}}!</div>
              <div>
                <img class="btn-close-popup" src="../assets/image/icons/icon-close.png" alt="I" (click)="switchUserPopup()">
              </div>
            </div>
            <div class="popup-user-links">
              <!-- <a href="/meu-perfil" rel="noopener noreferrer">Dados pessoais</a> -->
              <a href="https://login.testes.acesso.pe.gov.br/realms/govpe-hml/account/" rel="noopener noreferrer">Segurança da conta</a>
              <a href="/privacidade" rel="noopener noreferrer">Privacidade</a>
            </div>
            <div class="popup-user-footer flex-end">
              <a (click)="logoutAction()"><div class="btn btn-secondary">Sair da conta</div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-home-tabs></app-home-tabs>
  <div class="internal-header" *ngIf="isInternalMenuEnabled()" [hidden]="!isOnTop">
    <div class="container mobile-only">
      <app-search-box class="search-box-mobile-internal"></app-search-box>
    </div>
    <div class="container">
      <app-breadcrumbs *ngIf="isInternalMenuEnabled() && responsiveState == 'desktop'"></app-breadcrumbs>
      <div class="hamburguer-menu mobile-only">
        <img src="../assets/image/icons/icon-bars.svg" (click)="toggleMenu()" [class]="menuOpened ? 'active' : ''" [hidden]="menuOpened">
        <img src="../assets/image/icons/icon-bars-active.svg" (click)="toggleMenu()" [class]="menuOpened ? 'active' : ''" [hidden]="!menuOpened">
      </div>
      <div class="menu-mobile-section mobile-only">
        <label>Menu</label>
        <div class="flex acessibilidade-actions">
          <a href="javascript:IncreaseFontScale(-2)" class="high-contrast-invert"><img src="../assets/image/icons/icon-a-.png" alt="a-"></a>
          <a href="javascript:IncreaseFontScale(+2)" class="high-contrast-invert"><img src="../assets/image/icons/icon-a+.png" alt="a+"></a>
          <a href="javascript:ToggleHighContrast()" class="high-contrast-invert"><img src="../assets/image/icons/icon-contrast.png" alt="I"></a>
        </div>
      </div>
      <!-- <app-search-box [variation]="'2'" *ngIf="!menuOpened"></app-search-box> -->
    </div>
  </div>
  <div class="internal-menu" *ngIf="menuOpened">
    <a class="menu-item" href="/servicos">Serviços</a>
    <a class="menu-item" href="/">Serviços em destaque</a>
    <a class="menu-item" href="/agendamento">Agendamento</a>
    <a class="menu-item" href="/#acompanhar-agendamento">Acompanhar agendamento</a>
    <a class="menu-item" href="https://www.pe.gov.br/">Notícias</a>
    <a class="menu-item" href="/fale-conosco">Fale conosco</a>
  </div>
  <app-breadcrumbs *ngIf="isInternalMenuEnabled() && responsiveState != 'desktop'"></app-breadcrumbs>
</header>

<div [class]="isOnTop ? 'header-space-on-top' : 'header-space'" *ngIf="menuType == 'initial'"></div>
<div [class]="isOnTop ? 'header-space-internal-on-top' : 'header-space-internal'" *ngIf="menuType != 'initial'"></div>

<div class="content" role="main" *ngIf="!wordpressContent">
  <div class="container">
    <div class="row">
      <div class="col">
        <!-- <app-breadcrumbs *ngIf="isInternalMenuEnabled() && responsiveState == 'desktop'"></app-breadcrumbs> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

  <router-outlet *ngIf="!wordpressContent"></router-outlet>
  <div class="content" role="main" *ngIf="wordpressContent">
    <div class="container">
      <div class="row">
        <div class="col">
          <div [innerHTML]="wordpressContent" class="wordpressContent"></div>
        </div>
      </div>
    </div>
  </div>

<!-- Footer -->
<footer>
  <div class="footer">
    <div class="logo">
        <a href="/">
          <img src="../assets/image/logo-rodape.svg" alt="PE cidadão">
        </a>
    </div>
    <div class="footer-links">
      <div class="col-1/2">
        <a href="#" class="footer-link-title">Portal de Serviços</a>
        <a href="/">Início</a>
        <a href="/servicos">Serviços</a>
        <a href="/fale-conosco">Fale Conosco</a>
      </div>
      <div class="col-1/2">
        <a href="#" class="footer-link-title">Suporte</a>
        <a href="/o-que-ha-de-novo">O que há de novo?</a>
        <a href="/fale-conosco">Perguntas Frequentes</a>
        <a href="/privacidade">Privacidade</a>
      </div>
    </div>
    <div class="desktop-develop-right">
      <div class="footer-develop">
        <div>Desenvolvido por</div>
        <a href="/liga-digital">
          <img src="../assets/image/ligadigital-logo.svg">
          <img src="../assets/image/ligadigital-texto.svg">
        </a>
      </div>
      <div class="footer-socials">
        <a href="http://www.instagram.com/governope/" target="_blank"><img src="../assets/image/icons/icon-instagram.png" alt="media icon"></a>
        <!-- <a href="http://twitter.com/governope" target="_blank"><img src="../assets/image/icons/icon-twitter.png" alt="media icon"></a> -->
        <a href="https://www.youtube.com/@governodepernambuco7867" target="_blank"><img src="../assets/image/icons/icon-youtube.png" alt="media icon"></a>
      </div>
    </div>
  </div>
  <div class="footer-copyright" [innerHTML]="wordpressOptions.texto_rodape"></div>
  <!-- <div class="footer-develloppers">
    <div>DESENVOLVIDO POR:</div>
    <img src="../assets/image/logo-liga-digital.png" alt="Logo Liga Digital">
  </div> -->
</footer>
